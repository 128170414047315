import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import { Article } from "../../../interfaces/Article";

const CardArticle = ({ title, description, images }: Article) => {
  return (
    <Wrapper>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Image src={images.large} alt="" />
        </Col>
        <Col span={24}>
          <Title className="title">{title}</Title>
          <Description
            dangerouslySetInnerHTML={{ __html: description }}
          ></Description>
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &:hover {
    .title {
      color: #3d1a5b;
    }
  }
`;

const Title = styled.h3`
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  transition: color 0.2s;
  color: ${(p) => p.theme.color.black};
`;
const Image = styled.img`
  width: 100%;
  border-radius: 10px;
`;
const Description = styled.p`
  color: #333;
  color: ${(p) => p.theme.color.text};
`;

export default CardArticle;
