import React from "react";
import styled from "styled-components";
import { paragraph_xs } from "../../../global/mixins/text";
import { Team as ITeam } from "../../../interfaces/Team";

const Team = ({ name, logo, ...props }: ITeam) => {
  return (
    <Wrapper {...props}>
      {props.inverse ? (
        <>
          <div className="name">{name}</div>
          <img className="logo" src={logo} alt="" />
        </>
      ) : (
        <>
          <img className="logo" src={logo} alt="" />
          <div className="name">{name}</div>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${paragraph_xs};
  font-size: 12px;
  width: 100px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${(p) => p.theme.color.primary};

  ${(p) =>
    p.inverse &&
    `
    justify-content: flex-end;
    text-align: right;
  `}

  .name {
    font-size: 12px;
    width: 100px;
  }

  .logo {
    width: 30px;
    margin: 0 11px;
  }
`;

export default Team;
