import PageStatistics from "./statistics";
import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "styled-components";
import { THEME } from "../src/global/theme";

import WidgetStandings from "../src/widgets/WidgetStandings";
import WidgetArticles from "../src/widgets/WidgetArticles";
import WidgetMatches from "../src/widgets/WidgetMatches";
import PageFixtures from "./fixtures";

const PAGE_STATISTICS = document.getElementById("th-page-standings");
const PAGE_FIXTURES = document.getElementById("th-page-fixtures");
const WIDGET_STANDINGS = document.getElementById("th-widget-standings");
const WIDGET_ARTICLES = document.getElementById("th-widget-articles");
const WIDGET_FIXTURES = document.getElementById("th-widget-fixtures");

PAGE_STATISTICS &&
  ReactDOM.render(
    <ThemeProvider theme={THEME}>
      <PageStatistics />
    </ThemeProvider>,
    PAGE_STATISTICS
  );

PAGE_FIXTURES &&
  ReactDOM.render(
    <ThemeProvider theme={THEME}>
      <PageFixtures />
    </ThemeProvider>,
    PAGE_FIXTURES
  );
WIDGET_STANDINGS &&
  ReactDOM.render(
    <ThemeProvider theme={THEME}>
      <WidgetStandings />
    </ThemeProvider>,
    WIDGET_STANDINGS
  );
WIDGET_ARTICLES &&
  ReactDOM.render(
    <ThemeProvider theme={THEME}>
      <WidgetArticles term={1} limit={2} />
    </ThemeProvider>,
    WIDGET_ARTICLES
  );

WIDGET_FIXTURES &&
  ReactDOM.render(
    <ThemeProvider theme={THEME}>
      <WidgetMatches />
    </ThemeProvider>,
    WIDGET_FIXTURES
  );
