/* eslint-disable camelcase */
import { css } from 'styled-components'

// Titles
const h1 = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h1};
    font-weight: 900;
    line-height: 1.25;
`
const h2 = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h2};
    font-weight: 600;
    line-height: 1.45;
`
const h2_md = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h2_md};
    font-weight: 900;
    line-height: 43px;
`
const h2_sm = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h2_sm};
    font-weight: 900;
    line-height: 26px;
`
const h2_xs = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h2_xs};
    font-weight: 900;
    line-height: 21px;
`
const h3 = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h3};
    font-weight: 800;
    line-height: 1.3;
`
const h3_md = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h3_md};
    font-weight: 800;
    line-height: 1.3;
`
const h3_sm = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h3_sm};
    font-weight: 800;
    line-height: 1.3;
`
const h3_xs = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h3_xs};
    font-weight: 800;
    line-height: 1.3;
`

const h4 = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h4};
    font-weight: 600;
    line-height: 22px;
`

const h5 = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.h5};
    font-weight: 600;
    line-height: 28px;
`

// Paragraph
const p = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.p};
    line-height: 27px;
    font-weight: normal;
`

const p_secondary = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.p_secondary};
    font-weight: normal;
    line-height: 26px;
    font-family: ${theme.fonts.secondary};
`

const paragraph_md = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.p_md};
    line-height: 1.5;
`

const paragraph_sm = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.p_sm};
    font-weight: normal;
    line-height: 21px;
`

const paragraph_xs = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.p_xs};
    line-height: 1.5;
`
const paragraph_xxs = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.p_xxs};
    line-height: 1.5;
`
// Caps
const caps = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.caps};
    font-weight: normal;
    text-transform: uppercase;
    line-height: 15px;
`

const caps_lg = ({ theme }) => css`
    ${caps};
    font-size: ${theme.fonts.sizes.caps_lg};
    font-weight: 600;
`
const caps_xs = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.caps_xs};
    font-weight: normal;
    text-transform: uppercase;
    line-height: 7px;
`
const caps_sm = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.caps_sm};
    font-weight: normal;
    text-transform: uppercase;
    line-height: 10px;
`
// Button
const btn = ({ theme }) => css`
    font-size: ${theme.fonts.sizes.button};
    line-height: 21px;
`

export {
    h1,
    h2,
    h2_md,
    h2_sm,
    h2_xs,
    h3,
    h3_md,
    h3_sm,
    h3_xs,
    h4,
    h5,
    p,
    p_secondary,
    paragraph_sm,
    paragraph_md,
    paragraph_xs,
    paragraph_xxs,
    caps,
    caps_xs,
    caps_sm,
    caps_lg,
    btn,
}
