export const THEME = {
  breakpoints: {
    xs: 0,
    s: 425,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1366,
    xxl: 1600,
    hd: 1920,
  },
  color: {
    primary: "#350D3A",
    primary_light: "#5D3D61",

    secondary: "#FF2982",
    secondary_light: "#FF539B",

    third: "#973CFF",
    third_light: "#AB63FF",

    grey: "#E4E7EF",
    grey_light: "#F0F2F7",
    grey_dark: "#B9BED1",
    grey_darkest: "#B9BED1",

    text: "#3A405B",
    text_dark: "#222328",

    black: "#222328",
    white: "#FFFFFF",
  },
  fonts: {
    primary: `'Fira Sans', sans-serif`,
    secondary: `'Istok Web', sans-serif`,

    sizes: {
      h1: "48px",
      h2: "36px",
      h2_md: "35px",
      h2_sm: "21px",
      h2_xs: "17px",
      h3: "24px",
      h3_md: "23px",
      h3_sm: "20px",
      h3_xs: "16px",
      h4: "18px",
      h5: "16px",
      p: "18px",
      p_md: "16px",
      p_sm: "14px",
      p_xs: "12px",
      p_xxs: "10px",
      p_secondary: "18px",
      caps: "12px",
      caps_xs: "6px",
      caps_sm: "8px",
      caps_lg: "18px",
      button: "14px",
    },
  },
};

export const BREAKPOINTS = THEME.breakpoints;
