import React, { useState } from "react";
import { Row, Col, Spin, Select } from "antd";
import styled from "styled-components";

import useSWR from "swr";

import { NEST_API, fetcher } from "./api/constants";

import Table from "../src/components/core/Table";
import { standings as columnsStanding } from "../src/components/core/Table/columns/standings";
import columnsGoals from "../src/components/core/Table/columns/goals";
import columnsAssists from "../src/components/core/Table/columns/assists";
import { up } from "styled-breakpoints";

const SEASONS = [2015, 2016, 2017, 2018, 2019, 2020, 2021];

const PageStatistic = () => {
  const [season, setSeason] = useState(2021);

  const { data: scorers } = useSWR(
    `${NEST_API}/statistic/scorers/${season}`,
    fetcher
  );
  const { data: assists } = useSWR(
    `${NEST_API}/statistic/assists/${season}`,
    fetcher
  );
  const { data: standings } = useSWR(
    `${NEST_API}/statistic/standings/${season}`,
    fetcher
  );

  return (
    <Row gutter={[30, 30]}>
      <Col span={24}>
        <Select
          size="large"
          defaultValue={2021}
          style={{ width: 120 }}
          onChange={(value) => setSeason(value)}
        >
          {SEASONS.map((season) => (
            <Select.Option key={season} value={season}>
              {season}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col xs={24} lg={16}>
        <Spin spinning={!standings}>
          <Table
            className="standings"
            dataSource={standings}
            columns={columnsStanding}
            scroll={{ x: 700 }}
          />
        </Spin>
      </Col>
      <Players xs={24} lg={8}>
        <Row gutter={[30, 30]}>
          <Block xs={24} md={12} lg={24}>
            <Spin spinning={!scorers}>
              <Table
                className="players"
                dataSource={scorers}
                columns={columnsGoals}
                scroll={{ y: 410 }}
              />
            </Spin>
          </Block>
          <Block xs={24} md={12} lg={24}>
            <Spin spinning={!assists}>
              <Table
                className="players"
                dataSource={assists}
                columns={columnsAssists}
                scroll={{ y: 410 }}
              />
            </Spin>
          </Block>
        </Row>
      </Players>
    </Row>
  );
};

const Players = styled(Col)`
  ${up("lg")} {
    border-left: 1px solid #e5e5e5;
  }
`;

const Block = styled(Col)`
  & + & {
    ${up("lg")} {
      border-top: 1px solid #e5e5e5;
      padding-top: 20px;
    }
  }
`;

export default PageStatistic;
