import React from "react";
import { Select as AntSelect, SelectProps } from "antd";
import styled from "styled-components";

const Select = (props: SelectProps<any>) => {
  return <Wrapper {...props} />;
};

const Wrapper = styled(AntSelect)`
  .ant-select-selection-item {
    display: flex;
  }
`;

export default Select;
