import React from "react";
import useSWR from "swr";
import styled from "styled-components";
import { fetcher, NEST_API } from "../../../pages/api/constants";
import ListMatches from "../../components/composite/List/ListMatch/ListMatches";

const WidgetMatches = () => {
  const { data: results } = useSWR(`${NEST_API}/fixtures/last`, fetcher);
  const { data: fixtures } = useSWR(`${NEST_API}/fixtures/next`, fetcher);

  if (!fixtures || !results) return null;

  return (
    <>
      <Block>
        <Results title="Результаты" matches={results} />
      </Block>
      <Block>
        <Fixtures title="Следующие матчи" matches={fixtures} />
      </Block>
    </>
  );
};

const Results = styled(ListMatches)``;
const Fixtures = styled(ListMatches)``;
const Block = styled.div`
  & + & {
    margin-top: 20px;
  }

  .ant-list .ant-list-header {
    text-align: center;
  }

  .ant-list-bordered {
    border: 0;
  }
`;

export default WidgetMatches;
