import React, { useState } from "react";
import queryString from "query-string";
import useSWR from "swr";
import styled from "styled-components";
import { formatDate } from "../src/utils/formatDate";
import { NEST_API, fetcher } from "./api/constants";

import { Team as ITeam } from "../src/interfaces/Team";

import { Row, Col, Select as AntSelect, Spin, Empty } from "antd";
import Select from "../src/components/core/Select";
import Team from "../src/components/core/Team";
import Observer from "../src/components/hoc/Observer";
import ListMatches from "../src/components/composite/List/ListMatch/ListMatches";

const SEASONS = [2015, 2016, 2017, 2018, 2019, 2020, 2021];

const PageFixtures = () => {
  const [season, setSeason] = useState(2021);
  const [team, setTeam] = useState<ITeam | null>(null);

  const { data: teams } = useSWR(`${NEST_API}/teams`, fetcher);

  const stringified = queryString.stringify(
    { season, team },
    { skipNull: true }
  );

  const { data: fixtures } = useSWR(
    `${NEST_API}/fixtures?${stringified}`,
    fetcher
  );

  console.log(fixtures, "fixtures");

  return (
    <Row gutter={[10, 10]}>
      <Col>
        <Select
          defaultValue={2021}
          size="large"
          style={{ width: 120 }}
          onChange={(value) => setSeason(value)}
        >
          {SEASONS.map((season) => (
            <AntSelect.Option key={season} value={season}>
              {season}
            </AntSelect.Option>
          ))}
        </Select>
      </Col>
      <Col>
        <Select
          value={team?.id}
          allowClear
          size="large"
          defaultActiveFirstOption
          style={{ width: "200px" }}
          placeholder="Выберите команду"
          onChange={(value) => setTeam(value)}
          onClear={() => setTeam(null)}
        >
          {teams?.map((team) => (
            <AntSelect.Option key={team.name} value={team.id}>
              <Team {...team}></Team>
            </AntSelect.Option>
          ))}
        </Select>
      </Col>
      <Col span={24}>
        <Spin spinning={!fixtures}>
          <Fixtures>
            {fixtures?.length === 0 && <Empty description="Нет данных" />}
            {fixtures?.map((day) => {
              console.log(day.fixtures, "day.fixtures");
              return (
                <Observer key={day.id}>
                  <ListMatches
                    title={formatDate(day.date, "dd MMMM")}
                    matches={day.fixtures}
                  ></ListMatches>
                </Observer>
              );
            })}
          </Fixtures>
        </Spin>
      </Col>
    </Row>
  );
};

const Fixtures = styled.div``;

export default PageFixtures;
