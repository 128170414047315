import React from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import { Player } from "../../../../interfaces/Player";

const columns = [
  {
    title: () => <Header>Бомбардиры</Header>,
    key: "photo",
    render: (value: string, row: Player) => (
      <>
        <Personal>
          <img src={row.photo} alt="Фото игрока"></img>
          <Name>{row.name}</Name>
        </Personal>
      </>
    ),
  },
  {
    width: 60,
    key: "goals",
    title: () => (
      <Title>
        <Tooltip title="Голов забито">
          <span>ГЗ</span>
        </Tooltip>
      </Title>
    ),
    render: (value: string, row: Player) => row.goals,
  },
  {
    width: 60,
    key: "goals",
    showSorterTooltip: false,
    title: () => (
      <Title>
        <Tooltip title="Голов с пенальти">
          <span>П</span>
        </Tooltip>
      </Title>
    ),
    sorter: (prevRow: Player, currentRow: Player) =>
      prevRow.goals_penalty - currentRow.goals_penalty,
    render: (value: string, row: Player) => row.goals_penalty,
  },
];

const Header = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding-left: 10px;
`;

const Title = styled.span`
  border-bottom: 1px dashed #ccc;
  cursor: help;
`;

const Personal = styled.span`
  display: flex;
  align-items: center;
  img {
    width: 40px;
    border-radius: 100%;
  }
`;

const Name = styled.div`
  font-size: 16px;
  padding-left: 10px;
`;

export default columns;
