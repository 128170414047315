import React from "react";
import Icon from "../../../core/Icon";
import { CaretDownOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import styled from "styled-components";
import { Team } from "../../../../interfaces/Team";

const standingsShort = [
  {
    width: 10,
    key: "rank",
    render: (value: string, row: Team) => (
      <Position>
        {row.statistic.rank}
        <Icon hoverColor="" component={CaretDownOutlined} />{" "}
      </Position>
    ),
  },
  {
    width: 150,
    title: "Команда",
    key: "name",
    render: (value: string, row: Team) => (
      <Club>
        <img src={row.logo} alt="Логотип команды"></img>
        {row.name}
      </Club>
    ),
  },
  {
    width: 30,
    key: "played",
    title: () => (
      <Title>
        <Tooltip title="Матчей сыграно">
          <span>М</span>
        </Tooltip>
      </Title>
    ),
    render: (value: string, row: Team) => row.statistic.played,
  },
  {
    width: 30,
    key: "points",
    title: () => (
      <Title>
        <Tooltip title="Очки">
          <span>О</span>
        </Tooltip>
      </Title>
    ),
    render: (value: string, row: Team) => row.statistic.points,
  },
];

const standings = [
  {
    width: 10,
    key: "rank",
    render: (value: string, row: Team) => (
      <Position>
        {row.statistic.rank}
        <Icon hoverColor="" component={CaretDownOutlined} />{" "}
      </Position>
    ),
  },
  {
    width: 150,
    title: "Команда",
    key: "name",
    render: (value: string, row: Team) => (
      <Club>
        <img src={row.logo} alt="Логотип команды"></img>
        {row.name}
      </Club>
    ),
  },
  {
    width: 30,
    key: "played",
    title: () => (
      <Title>
        <Tooltip title="Матчей сыграно">
          <span>М</span>
        </Tooltip>
      </Title>
    ),
    render: (value: string, row: Team) => row.statistic.played,
  },
  {
    width: 30,
    key: "wins",
    showSorterTooltip: false,
    title: () => (
      <Title>
        <Tooltip title="Победы">
          <span>В</span>
        </Tooltip>
      </Title>
    ),
    sorter: (prevRow: Team, currentRow: Team) =>
      prevRow.statistic.win - currentRow.statistic.win,
    render: (value: string, row: Team) => row.statistic.win,
  },
  {
    width: 30,
    key: "draws",
    showSorterTooltip: false,
    title: () => (
      <Title>
        <Tooltip title="Ничьи">
          <span>Н</span>
        </Tooltip>
      </Title>
    ),
    sorter: (prevRow: Team, currentRow: Team) =>
      prevRow.statistic.draw - currentRow.statistic.draw,

    render: (value: string, row: Team) => row.statistic.draw,
  },
  {
    width: 30,
    key: "lose",
    showSorterTooltip: false,
    title: () => (
      <Title>
        <Tooltip title="Поражения">
          <span>П</span>
        </Tooltip>
      </Title>
    ),
    sorter: (prevRow: Team, currentRow: Team) =>
      prevRow.statistic.lose - currentRow.statistic.lose,
    render: (value: string, row: Team) => row.statistic.lose,
  },
  {
    width: 30,
    key: "for",
    showSorterTooltip: false,
    title: () => (
      <Title>
        <Tooltip title="Голов забито">
          <span>ГЗ</span>
        </Tooltip>
      </Title>
    ),
    sorter: (prevRow: Team, currentRow: Team) =>
      prevRow.statistic.goals.for - currentRow.statistic.goals.for,
    render: (value: string, row: Team) => row.statistic.goals.for,
  },
  {
    width: 30,
    key: "goals_from",
    showSorterTooltip: false,
    title: () => (
      <Title>
        <Tooltip title="Голов пропущено">
          <span>ГП</span>
        </Tooltip>
      </Title>
    ),
    sorter: (prevRow: Team, currentRow: Team) =>
      prevRow.statistic.goals.against - currentRow.statistic.goals.against,
    render: (value: string, row: Team) => row.statistic.goals.against,
  },
  {
    width: 30,
    key: "points",
    title: () => (
      <Title>
        <Tooltip title="Очки">
          <span>О</span>
        </Tooltip>
      </Title>
    ),
    render: (value: string, row: Team) => row.statistic.points,
  },
];

const Title = styled.span`
  border-bottom: 1px dashed #ccc;
  cursor: help;
`;

const Club = styled.span`
  font-size: 16px;
  img {
    width: 30px;
    margin-right: 14px;
  }

  .standings_short & {
    font-size: 14px;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
`;

const Position = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 10px;
    margin-left: 5px;
    color: green;
  }
`;

export { standings, standingsShort };
