import { Button } from "antd";
import React from "react";
import useSWR from "swr";
import { fetcher, NEST_API } from "../../../pages/api/constants";
import Table from "../../components/core/Table";
import { standingsShort as columnsStandingsShort } from "../../components/core/Table/columns/standings";

const WidgetStandings = ({ type = "standings_short", season = 2021 }) => {
  const { data: standings } = useSWR(
    `${NEST_API}/statistic/standings/${season}`,
    fetcher
  );

  return (
    <>
      <Table
        dataSource={standings}
        columns={columnsStandingsShort}
        size="small"
        className={type}
      />
      <br />
      <a href="/standings">
        <Button type="primary" block>
          Подробная статистика
        </Button>
      </a>
    </>
  );
};

export default WidgetStandings;
