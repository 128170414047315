import React from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import Placeholder from "../../core/Placeholder";
import { ObserverProps } from "./types";

const Observer = ({ className, children, options }: ObserverProps) => {
  const { ref, inView } = useInView(
    options || {
      rootMargin: "300px",
      triggerOnce: true,
    }
  );
  return (
    <Wrapper className={className} ref={ref}>
      {inView ? children : <Placeholder />}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default Observer;
