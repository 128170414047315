import { Col, Row, Skeleton, Spin } from "antd";
import React from "react";
import styled from "styled-components";

const Placeholder = ({ grid, loading = false }) => {
  const skeletons = [...new Set([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])];
  return (
    <Spin spinning={loading}>
      {grid ? (
        <Row gutter={[60, 60]}>
          {skeletons.map((s, i) => (
            <Col key={i} xs={8}>
              <Skeleton active></Skeleton>
            </Col>
          ))}
        </Row>
      ) : (
        <Wrapper />
      )}
    </Spin>
  );
};
const Wrapper = styled.div`
  padding-bottom: calc(100% / (16 / 9));
`;

export default Placeholder;
