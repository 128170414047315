import React from "react";
import useSWR from "swr";
import styled from "styled-components";
import { fetcher, NEST_API } from "../../../pages/api/constants";
import CardArticle from "../../components/composite/Card";
import { Article } from "../../interfaces/Article";
import { Row } from "antd";

const WidgetArticles = ({
  title,
  term,
  limit = 10,
}: {
  title: string;
  term: number;
  limit: number;
}) => {
  const { data: articles } = useSWR(
    `${NEST_API}/articles/category/${term}?limit=${limit}`,
    fetcher
  );

  return (
    <>
      {title && <Title>{title}</Title>}
      <List gutter={[0, 10]}>
        {articles?.map((article: Article) => {
          return (
            <Card key={article.id}>
              <a href={article.link}>
                <CardArticle {...article}></CardArticle>
              </a>
            </Card>
          );
        })}
      </List>
    </>
  );
};

const Card = styled.div`
  & + & {
    border-top: 1px solid rgba(0, 0, 0, 0.086);
    padding-top: 0px;
  }
`;

const Title = styled.h3``;
const List = styled(Row)`
  &:not(:first-child) {
    // display: none;
  }

  ${Card}:not(:first-child) {
    img {
      display: none;
    }
  }
`;

export default WidgetArticles;
