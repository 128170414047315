import React from "react";
import { Table as AntTable, TableProps } from "antd";
import styled from "styled-components";

const Table = (props: TableProps<any>) => {
  return (
    <StyledTable
      pagination={false}
      bordered
      size="small"
      locale={{ emptyText: "Нет данных" }}
      {...props}
    />
  );
};

const StyledTable = styled(AntTable)`
  tbody {
    tr {
      &:hover {
        td {
          background: inherit !important;
        }
      }
    }

    tr:nth-child(2n + 2) {
      background-color: #fafafa;
    }
  }

  &.standings,
  &.standings_short {
    .ant-table-cell:not(:nth-child(2)) {
      text-align: center;
    }

    tbody {
      tr {
        td:last-child {
          font-weight: 600;
        }

        td {
          border-bottom: 0 !important;
        }
      }

      tr:nth-child(-n + 5) {
        td:first-child {
          border-left: 3px solid #172e58 !important;
        }
      }

      tr:nth-last-child(-n + 3) {
        td:first-child {
          border-bottom-width: 2px;

          border-left: 3px solid red !important;
        }
      }

      tr:nth-child(6),
      tr:nth-child(7),
      tr:nth-child(8) {
        td:first-child {
          border-left: 3px solid #f08a00 !important;
        }
      }

      td:nth-child(1) {
        font-weight: 500;
      }
    }
  }

  &.standings_short {
    td,
    th {
      padding: 6px !important;
    }
  }

  &.players {
    .ant-table-cell:not(:first-child) {
      text-align: center;
    }
  }
`;

export default Table;
