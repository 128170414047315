import axios from "axios";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

const NEST_API =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8002"
    : "https://eplg-nest-nadam131.vercel.app";

console.log(process.env.NODE_ENV, "process.env.NODE_ENV");

export { fetcher, NEST_API };
