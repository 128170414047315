import React from "react";
import styled from "styled-components";

import AntIcon from "@ant-design/icons";

// TODO Importing svg-component add width, height attribute from font-size
const Icon = ({ hoverColor, ...props }) => {
  return (
    <Wrapper hoverColor={hoverColor}>
      <AntIcon {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.i`
  path {
    fill: inherit;
    transition: fill 1s;
  }

  &:hover {
    path {
      fill: ${(p) => p.hoverColor || "inherit"};
    }
  }
`;

export default Icon;
