import React from "react";
import styled from "styled-components";
import { Fixture as IFixture } from "../../../interfaces/Fixture";
import { paragraph_sm } from "../../../global/mixins/text";
import { formatDate } from "../../../utils/formatDate";
import Team from "../../core/Team";
import { isToday } from "date-fns";

const Fixture = ({
  homeTeam,
  awayTeam,
  goalsHomeTeam,
  goalsAwayTeam,
  statusShort,
  date,
}: IFixture) => {
  const newDate = new Date(date);
  const formatedDay = formatDate(date, isToday(newDate) ? "p" : "dd.MM | p");
  return (
    <Wrapper>
      <Team inverse name={homeTeam.name.substring(0, 3)} logo={homeTeam.logo} />
      {statusShort === "FT" ? (
        <Score>
          <div className="score-team">{goalsHomeTeam}</div>
          <span />
          <div className="score-team">{goalsAwayTeam}</div>
        </Score>
      ) : (
        <Score>
          <div className="score-team">{formatedDay}</div>
        </Score>
      )}
      <Team name={awayTeam.name.substring(0, 3)} logo={awayTeam.logo} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Score = styled.div`
  display: flex;
  align-items: center;
  ${paragraph_sm};
  background-color: ${(p) => p.theme.color.primary};
  margin: 0 5px;

  .score-team {
    padding: 4px 10px;
    color: ${(p) => p.theme.color.white};
  }

  span {
    height: 15px;
    width: 1px;
    background-color: ${(p) => p.theme.color.grey_dark};
  }
`;

export default Fixture;
