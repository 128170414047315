import { List as AntList, Grid } from "antd";
import styled from "styled-components";
import React from "react";
import Fixture from "../../Fixture";
import { h4 } from "../../../../global/mixins/text";
import { Fixture as IFixture } from "../../../../interfaces/Fixture";

const ListMatches = ({
  title,
  matches,
}: {
  title: string;
  matches: IFixture[];
}) => {
  return (
    <List
      header={<Title style={{ margin: 0 }}>{title}</Title>}
      grid={{
        xs: 1,
        md: 3,
        lg: 4,
      }}
      dataSource={matches}
      bordered
      renderItem={(match: any): any => {
        return <Fixture key={match.id} {...match} />;
      }}
    />
  );
};

const List = styled(AntList)`
  margin-top: 20px;
  .ant-spin-nested-loading {
    padding: 20px 0;
  }

  .ant-row {
    margin-top: -15px;
    margin-bottom: -15px;
    & > div {
      width: 100%;
      padding: 15px;
    }
  }
`;

const Title = styled.h3`
  ${h4};
`;

export default ListMatches;
